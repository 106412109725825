import { createAction } from "redux-actions";

export const GET_APPLICATION_STATUS = "GET_APPLICATION_STATUS";
export const getApplicationStatus = createAction(GET_APPLICATION_STATUS);

export const UPLOAD_DIRECTOR_ID = "UPLOAD_DIRECTOR_ID";
export const uploadDirectorID = createAction(UPLOAD_DIRECTOR_ID);

export const UPLOAD_ARTICLE_INC = "UPLOAD_ARTICLE_INC";
export const uploadArticleInc = createAction(UPLOAD_ARTICLE_INC);

export const SUBMIT_VERIFICATION_APPLICATION =
  "SUBMIT_VERIFICATION_APPLICATION";
export const submitVerificationApplication = createAction(
  SUBMIT_VERIFICATION_APPLICATION
);

export const DELETE_IMAGE = "DELETE_IMAGE";
export const deleteImage = createAction(DELETE_IMAGE);

export const FETCH_DIRECTORS = "FETCH_DIRECTORS";
export const fetchDirectors = createAction(FETCH_DIRECTORS);


export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const deleteApplication = createAction(DELETE_APPLICATION);

export const FETCH_DIRECTOR_FILE = "FETCH_DIRECTOR_FILE";
export const fetchDirectorFile = createAction(FETCH_DIRECTOR_FILE);


