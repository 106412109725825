import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
  //   Legend
);

export const options = {
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: 'Chart.js Bar Chart - Stacked',
  //     },
  //   },
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: false,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: false,
      display: false,
    },
  },
};

function InsightsChart(props) {
  const labels = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JULY",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [inflowData, setInflowData] = useState([]);
  const [outflowData, setOutflowData] = useState([]);
  useEffect(() => {
    if (Object.keys(props.data).length) {
      setInflowData(labels.map((el, i) => props?.data?.inflow[i]));
      setOutflowData(labels.map((el, i) => props?.data?.inflow[i]));
    } else {
      setOutflowData([]);
      setOutflowData([]);
    }
  }, [props.data]);
  const data = {
    labels,
    datasets: [
      {
        label: "Inflow",
        data: inflowData,
        backgroundColor: "#A537DC",
        stack: "Stack 0",
      },
      {
        label: "Outflow",
        data: outflowData,
        backgroundColor: "#829DEE",
        stack: "Stack 1",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
export default InsightsChart;
