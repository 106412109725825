import { all, put, takeLatest } from "redux-saga/effects";
import {
  ADD_CUSTOMER,
  GET_ALL_CUSTOMERS,
  GET_ALL_BENEFICIARY,
  ADD_BENEFICIARY,
  GET_ALL_QUEUED_PAYMENTS,
  INITIATE_PAYMENT ,
  ADD_AUTHORIZER,
  ADD_MAKER
} from "../actions/payments.actions.types";
import { paymentsSlice } from "../slices/payments";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* addCustomer({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/leanTech/payments/customer",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* getAllcustomers({
  payload: {
    query: { limit, skip },
    callback,
  },
}) {
  const payload = {
    method: "get",
    url: `/leanTech/payments/customer?limit=${limit}&skip=${skip}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(paymentsSlice.actions.customersList(result.data));
      callback({ success: true, data: result.data });
    }
  }
}

function* addBeneficiary({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/leanTech/payments/beneficiary",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* getAllBeneficiary({ payload: { id, callback } }) {
  const payload = {
    method: "get",
    url: `/leanTech/payments/beneficiary/${id}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(paymentsSlice.actions.beneficiariesList(result.data));
      callback({ success: true, data: result.data });
    }
  }
}
function* initiatePayment({ payload: { data, callback } }) {
  
  const payload = {
    data,
    method: "post",
    url: `/leanTech/payments/intent`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      // yield put(paymentsSlice.actions.beneficiariesList(result.data));
      callback({ success: true, data: result.data });
    }
  }
}

function* getAllQueuedPayments({ payload: { id, callback } }) {
  const payload = {
    method: "get",
    url: `/leanTech/payments/queued?customer_id=${id}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(paymentsSlice.actions.queuedPaymentsList(result.data.queued_payments));
      callback({ success: true, data: result.data });
    }
  }
}

function* addAuthorizer({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/leanTech/payments/authorizer`,
  };
  
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(paymentsSlice.actions.addAuthorizer(result.data));
      callback({ success: true, data: result.data });
    }
  }
}


function* addMaker({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/leanTech/payments/maker`,
  };
  
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(paymentsSlice.actions.addMaker(result.data));
      callback({ success: true, data: result.data });
    }
  }
}

function* Payments() {
  yield all([takeLatest(ADD_CUSTOMER, addCustomer)]);
  yield all([takeLatest(GET_ALL_CUSTOMERS, getAllcustomers)]);
  yield all([takeLatest(ADD_BENEFICIARY, addBeneficiary)]);
  yield all([takeLatest(GET_ALL_BENEFICIARY, getAllBeneficiary)]);
  yield all([takeLatest(INITIATE_PAYMENT, initiatePayment)]);
  yield all([takeLatest(GET_ALL_QUEUED_PAYMENTS, getAllQueuedPayments)]);
  yield all([takeLatest(ADD_AUTHORIZER, addAuthorizer)]);
  yield all([takeLatest(ADD_MAKER, addMaker)]);
}

export default Payments;
