import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  customersList: [],
  beneficiariesList: [],
  queuedPaymentsList: [],
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    customersList: (state, { payload }) => {
      return {
        ...state,
        customersList: payload,
      };
    },
    beneficiariesList: (state, { payload }) => {
      return {
        ...state,
        beneficiariesList: payload,
      };
    },
    queuedPaymentsList: (state, { payload }) => {
      return {
        ...state,
        queuedPaymentsList: payload,
      };
    },
    addAuthorizer: (state, { payload }) => {
      return {
        ...state,
        customersList: [...state.customersList.filter((el) => (el.id === payload.id ? payload : el))],
      };
      
    },
    addMaker: (state, { payload }) => {
      return {
        ...state,
        customersList: [...state.customersList.filter((el) => (el.id === payload.id ? payload : el))],
      }
    }
  },
  extraReducers: {},
});
