import { createSlice } from "@reduxjs/toolkit";
const initialState = { employeeList: [] };

export const employerSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    employerList: (state = initialState, { payload }) => {
      return {
        ...state,
        employeeList: payload,
      };
    },
    deleteEmployee: (state = initialState, { payload }) => {
      return {
        ...state,
        employeeList: state.employeeList.filter(el=>el.user.id!==payload.id),
      };
    },
  },
  extraReducers: {},
});
