import { createAction } from "redux-actions";

export const FETCH_CARDS = "FETCH_CARDS";
export const fetchCards = createAction(FETCH_CARDS);

export const ORDER_PHYSICAL_CARD = "ORDER_PHYSICAL_CARD";
export const orderPhysicalCard = createAction(ORDER_PHYSICAL_CARD);

export const ORDER_VIRTUAL_CARD = "ORDER_VIRTUAL_CARD";
export const orderVirtualCard = createAction(ORDER_VIRTUAL_CARD);
