import moment from "moment";
import React, { useState } from "react";

function SpendCategoriesAccordionItem({ item, label }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          onClick={() => setOpen(!open)}
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <div>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              // xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <rect width="18" height="18" fill="url(#pattern0)"></rect>
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#image0_559_488"
                    transform="scale(0.0277778)"
                  ></use>
                </pattern>
                <image
                  id="image0_559_488"
                  width="36"
                  height="36"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANJSURBVHgB7Vc9chMxFH5aMhAqwgmymYEZl+EGm4qfBuUETjq6xCVV1ieIfYPNDUTDbxG7o8N0KZjJ5gamgcAwMd/TPpG1rF1nJ06Gwt/MRrL0nvS9J+mTQrTEEteDcpVnWicoklJf/t6YTPpSWgAwXirj7aCIXfsF0eijMYbrK2JwAGap5z/ElwnrA1oMUinbqhT8HXxPte58MKYXcQP+aN9zQjTmUmu9RgtCcjnWmd8HDntS2skfBvxtCs+JNmk+zigwiY/Vy7GyKpuoov3I7R9gp8ImRyC7PxHMO2Ni/rjObdxX4dPmPxh7wHOEDOymfq51jmJdFcvUfyub74XWm5jgS8CvDwL7VAP4ppPA3kPblhByNkwy5iAw5oYlhE0dC/PcOXIbOo+pdBokggyEd50NBtxH20sOBvURmrtuHASauayUkAupqbnc/MqPgDce1noPa7kPx7WqwTh7+H0csBn/gc0nY0Z8IH4TnYZs0NbD/uwPjBl7Adsj10PlAWcDRDYDA8xkB9Gfkpe98oTYTxs8Gex6JCcoBMw1kEDHOPYdq0Ny7NfFoBKI/I2Q0TVkGJxltsl4GVWNodMjVRyETkQNEIk2Ta4mBbGUOTVAI0IXspRyGuchF9tGwhqVnOcKWySZuYeluAKpgZQJzcc/YZ1ZXr5kVSGG7YDjGFphVR37iHXoMDQ6/LtOy2o2/xBLnzpNcrjjW307Ocnxmcet1nfm53WvPmq1FPoH+D6jfqaKrLllGWGS1yDdk+D40tYBMh3YvOK5aDYY62gFUE5E37GuygL2kr2Z3W/RrrUpsdvebqvJJKsg4wgnmHNPguLnztbU1eE8JJVd6WOyiT/qpLggu2USjtz94soIXS0jkHkiZPwnj706VgJOnLYUDkPOFCY+UgFCss92YMc2Y1V8HGmlJMCuL2TiwPvLYqXGmdd+QJenhSrIJ3XCV8a5PGmoggzDHXsT6LCR+ktyHZTurXW/T0ngNkPQlfRXcVLiks2oVB/SAoHJv1JJcpjMD1y2RGEdiukW4a/AFKE6sbsp+BLS6C67DfzfS+YgT9PDpjd1E0ACtv3XIqNKh5IofActDHcLsZ2Rm0pN8//dXSRU8Wro0RJL3AD+Ao+rVKCT2x5aAAAAAElFTkSuQmCC"
                ></image>
              </defs>
            </svg>

            {label.replaceAll("_", " ")}
          </div>

          <span>{Object.keys(item).length} Transaction(s)</span>
        </button>
      </h2>
      <div
        id="collapseOne"
        className={`accordion-collapse collapse ${open && "show"}`}
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div className="row">
            <div className="col-12">
              {Object.keys(item)
                .sort()
                .map((el, i) => (
                  <div key={`accordion-${i}`}>
                    <div className="salery d-flex align-items-center justify-content-between">
                      <h6>{el}</h6>
                      <p>{item[el].amount.toFixed(2)}</p>
                    </div>
                    <div className="trans-info d-flex align-items-center justify-content-between">
                      <p>Transaction Count</p>
                      <p>{item[el].count}</p>
                    </div>
                    <div className="trans-info d-flex align-items-center justify-content-between">
                      <p>First Payment</p>
                      <p>{moment(item[el].first_paid).format("YYYY-MM-DD")}</p>
                    </div>
                    <div className="trans-info d-flex align-items-center justify-content-between">
                      <p>Last Payment</p>
                      <p>{moment(item[el].last_paid).format("YYYY-MM-DD")}</p>
                    </div>
                    <div className="trans-info d-flex align-items-center justify-content-between">
                      <p>Regularity</p>
                      <p>{item[el].regularity.toFixed(2)}</p>
                    </div>
                    <div className="trans-info d-flex align-items-center justify-content-between">
                      <p>Avg. Days Between Payments</p>
                      <p>{item[el].avg_days_between_trans}</p>
                    </div>
                    <div className="trans-info d-flex align-items-center justify-content-between">
                      <p>Is A Recurring Charge?</p>
                      <p>{item[el].count > 1 ? "True" : "False"}</p>
                    </div>
                    <hr className="my-3" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpendCategoriesAccordionItem;
