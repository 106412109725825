import { all, takeLatest, put } from "redux-saga/effects";
import { bfmSlice } from "../slices/bfm";
import {
  BANK_ENTITIES,
  TRANX_DETAIL,
  REGULAR_SPEND_CATEGORIES,
  TRANSACTION_GRAPH_DATA,
  LEAN_IDENTITY,
} from "../actions/bfm.actions.type";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* bankEntities({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: "/leanTech/get-all-accounts",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* tranxDetail({ payload: { data, callback, accountId, entityId } }) {
  const payload = {
    data,
    method: "get",
    url: `/leanTech/get-all-transactions/${accountId}/${entityId}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(bfmSlice.actions.tranxDetail(result?.data));
      callback({ success: true, data: result });
    }
  }
}
function* getRegularSpendCategories({
  payload: { data, callback, accountId, entityId, period },
}) {
  const payload = {
    data,
    method: "get",
    url: `/leanTech/get-transactions-category-wise/${accountId}/${entityId}?period=${period}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(
        bfmSlice.actions.getRegularSpendCategories({
          data: result?.data.groupedTransactions,
          uid: `${accountId}-${entityId}`,
        })
      );
      yield put(
        bfmSlice.actions.getCashFlowData({
          data: {
            monthWiseTotals: result?.data.monthWiseTotals,
            totals: result?.data.totals,
          },
          uid: `${accountId}-${entityId}`,
        })
      );
      callback({ success: true, data: result });
    }
  }
}

function* getTransactionsGraphData({
  payload: { data, callback, accountId, entityId },
}) {
  const payload = {
    data,
    method: "get",
    url: `/leanTech/transactions-page-graph-data/${accountId}/${entityId}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(
        bfmSlice.actions.getTransactionsGraphData({
          data: result?.data,
          uid: `${accountId}-${entityId}`,
        })
      );
      callback({ success: true, data: result });
    }
  }
}

function* getLeanIdentity({ payload: { data, callback, entityId } }) {
  const payload = {
    data,
    method: "get",
    url: `/leanTech/current-user/${entityId}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(bfmSlice.actions.getIdentity({ data: result?.data }));
      callback({ success: true, data: result });
    }
  }
}
function* Bfm() {
  yield all([takeLatest(BANK_ENTITIES, bankEntities)]);
  yield all([takeLatest(TRANX_DETAIL, tranxDetail)]);
  yield all([takeLatest(REGULAR_SPEND_CATEGORIES, getRegularSpendCategories)]);
  yield all([takeLatest(TRANSACTION_GRAPH_DATA, getTransactionsGraphData)]);
  yield all([takeLatest(LEAN_IDENTITY, getLeanIdentity)]);
}

export default Bfm;
