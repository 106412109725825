import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {Helmet} from "react-helmet";
export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <button
      className="btn dropdown-toggle bg-trans"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  );
});

export const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
     
        <ul>
          {React.Children.toArray(children).filter(
            (child) => child.props.children
          )}
        </ul>
      </div>
    );
  }
);
