import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  businessDetails: null,
  directorList: [],
  directorDocs: null,
  applicationSubmitted: false,
  applicationStatus: null,
  businessData: null,
};

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    uploadDirectorIDAction: (state = initialState, { payload }) => {
      return {
        ...state,
        directorDocs: {
          ...state.directorDocs,
          [payload.user_id]: payload,
        },
      };
    },
    uploadArticleIncAction: (state = initialState, { payload }) => {
      return {
        ...state,
        businessDetails: { ...state.businessDetails, article_of_inc: payload },
      };
    },
    deleteImageAction: (state = initialState, { payload }) => {
      if (payload.file_class === "article_of_incorporation") {
        return {
          ...state,
          businessDetails: { ...state.businessDetails, article_of_inc: null },
        };
      } else if (payload.file_class === "passport") {
        delete state.directorDocs[payload.user_id];
        return state;
      } else {
        return state;
      }
    },
    fetchDirectorsAction: (state = initialState, { payload }) => {
      return {
        ...state,
        directorList: [...payload],
      };
    },
    submitApplicationAction: (state = initialState, { payload }) => {
      return { ...state, applicationSubmitted: true };
    },
    getApplicationStatusAction: (state = initialState, { payload }) => {
      return { ...state, applicationStatus: payload };
    },
    deleteApplicationAction: (state = initialState, { payload }) => {
      return { ...state, applicationStatus: null, applicationSubmitted: false };
    },
    fetchDirectorFileAction: (state = initialState, { payload }) => {
      if (payload === null) {
        return state;
      }
      return {
        ...state,
        directorDocs: {
          ...state.directorDocs,
          [payload.user_id]: payload,
        },
      };
    },
    fetchBusinessDataAction: (state = initialState, { payload }) => {
      if (payload === null) {
        return state;
      }
      return {
        ...state,
        businessData: payload,
      };
    },
  },
  extraReducers: {},
});
