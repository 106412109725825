import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  plan: null,
  members: null,
};

export const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setPlan: (state, { payload }) => {
      return {
        ...state,
        plan: payload.plan,
        members: payload.members,
      };
    },
  },
  extraReducers: {},
});
