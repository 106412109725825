import { createAction } from "redux-actions";

export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const addEmployee = createAction(ADD_EMPLOYEE);

export const RESEND_INVITATION = "RESEND_INVITATION";
export const resendInvitation = createAction(RESEND_INVITATION);

export const EMPLOYEE_LIST = "EMPLOYEE_LIST";
export const employeeList = createAction(EMPLOYEE_LIST);

export const ACCEPT_INVITATION = "ACCEPT_INVITATION";
export const acceptInvitation = createAction(ACCEPT_INVITATION);

export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const deleteEmployee = createAction(DELETE_EMPLOYEE);

export const EMPLOYEE_ASSIGNED_CARDS = "EMPLOYEE_ASSIGNED_CARDS";
export const employeeAssignedCards = createAction(EMPLOYEE_ASSIGNED_CARDS);
