import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { errorMessage, successMessage } from "../../utilities/notification";

function SelectChartPeriodDropdown({
  accountId,
  entityId,
  fetchRegularSpendCategories,
}) {
  const dispatch = useDispatch();
  const onSelect = (e) => {
    try {
      const callback = (data) => {
        if (data.success) {
          successMessage("Data fetched successfully!");
        }
      };

      dispatch(
        fetchRegularSpendCategories({
          accountId,
          entityId,
          period: e.target.value,
          callback,
        })
      );
    } catch (error) {
      errorMessage(error.reason);
    }
  };

  return (
    <div className="container w-25">
      <select
        className="form-control form-control-sm"
        onChange={onSelect}
        defaultValue={"p_1_M"}
      >
        <option value="p_7_d">Last 7 days</option>
        <option value="p_1_M">Last 1 month</option>
        <option value="p_3_M">Last 3 months</option>
        <option value="p_6_M">Last 6 months</option>
      </select>
    </div>
  );
}

export default SelectChartPeriodDropdown;
