import { all, takeLatest } from "redux-saga/effects";
import { PAYTABS_PRODUCT_LIST } from "../actions/paytabs.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* paytabsProductList({ payload: { callback } }) {
  const payload = {
    method: "get",
    url: "/paytabs/get-product-list",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Bfm() {
  yield all([takeLatest(PAYTABS_PRODUCT_LIST, paytabsProductList)]);
}

export default Bfm;
