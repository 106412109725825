import { createAction } from "redux-actions";

export const BANK_ENTITIES = "BANK_ENTITIES";
export const bankEntities = createAction(BANK_ENTITIES);

export const TRANX_DETAIL = "TRANX_DETAIL";
export const tranxDetail = createAction(TRANX_DETAIL);

export const REGULAR_SPEND_CATEGORIES = "REGULAR_SPEND_CATEGORIES";
export const fetchRegularSpendCategories = createAction(REGULAR_SPEND_CATEGORIES);

export const TRANSACTION_GRAPH_DATA = "TRANSACTION_GRAPH_DATA";
export const fetchTransactionsGraphData = createAction(TRANSACTION_GRAPH_DATA);

export const LEAN_IDENTITY = "LEAN_IDENTITY";
export const fetchLeanIdentity = createAction(LEAN_IDENTITY);
