import { all, takeLatest, put } from "redux-saga/effects";
import { anbSlice } from "../slices/anb";
import {
  ANB_ACCOUNTS,
  ANB_TRANSACTION,
  ANB_TRANSFER,
} from "../actions/anb.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* anbAccounts({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: "/anb/balance",
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
      yield put(anbSlice.actions.accountList(result?.data?.accounts));
    }
  }
}

function* getAnbTransactions({ payload: { data, callback } }) {
  const payload = {
    method: "get",
    url: `/anb/statement?accountNumber=${data.accountNumber}&fromDate=${data.fromDate}&toDate=${data.toDate}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
      yield put(
        anbSlice.actions.fetchTransactions(result?.data?.statement?.data)
      );
    }
  }
}
function* anbTransferFunds({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/anb/payment`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
      // yield put(
      //   anbSlice.actions.fetchTransactions(result?.data?.statement?.data)
      // );
    }
  }
}

function* Anb() {
  yield all([takeLatest(ANB_ACCOUNTS, anbAccounts)]);
  yield all([takeLatest(ANB_TRANSACTION, getAnbTransactions)]);
  yield all([takeLatest(ANB_TRANSFER, anbTransferFunds)]);
}
export default Anb;
