import { createSlice } from "@reduxjs/toolkit";
const initialState = { user: null };

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    // userDetail: (state = initialState, { payload }) => {
    //   return {
    //     ...state,
    //     user: {
    //       ...payload,
    //     },
    //   };
    // },
  },
  extraReducers: {},
});
