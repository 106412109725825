import { createSlice } from "@reduxjs/toolkit";
const initialState = { accountList: [], transactions: [] };

export const anbSlice = createSlice({
  name: "anb",
  initialState,
  reducers: {
    accountList: (state = initialState, { payload }) => {
      return {
        ...state,
        accountList: payload,
      };
    },
    fetchTransactions: (state = initialState, { payload }) => {
      return { ...state, transactions: payload };
    },
  },
  extraReducers: {},
});
