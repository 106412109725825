import { createAction } from "redux-actions";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const addCustomer = createAction(ADD_CUSTOMER);

export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const getAllCustomers = createAction(GET_ALL_CUSTOMERS);

export const ADD_BENEFICIARY = "ADD_BENEFICIARY";
export const addBeneficiary = createAction(ADD_BENEFICIARY);

export const GET_ALL_BENEFICIARY = "GET_ALL_BENEFICIARY";
export const getAllBeneficiary = createAction(GET_ALL_BENEFICIARY);

export const INITIATE_PAYMENT = "INITIATE_PAYMENT";
export const initiatePayment = createAction(INITIATE_PAYMENT);

export const GET_ALL_QUEUED_PAYMENTS = "GET_ALL_QUEUED_PAYMENTS";
export const getAllQueuedPayments = createAction(GET_ALL_QUEUED_PAYMENTS);

export const ADD_AUTHORIZER = "ADD_AUTHORIZER";
export const addAuthorizer = createAction(ADD_AUTHORIZER);

export const ADD_MAKER = "ADD_MAKER";
export const addMaker = createAction(ADD_MAKER)
