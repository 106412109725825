import React, { useEffect } from "react";
import { ReactNotifications } from "react-notifications-component";
import Route from "../../routes";
//import "./App.scss";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    for (const el of document.querySelectorAll('[data-react-helmet="true"]')) {
      document.getElementsByTagName("head")[0].removeChild(el);
    }
    document.body.setAttribute("dir", i18n.dir());
  }, [i18n.language, i18n]);

  return (
    <React.Fragment>
      {i18n.language === "ar" && (
        <Helmet>
          <link href="/custom-rtl.css" rel="stylesheet" />
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.rtl.min.css" integrity="sha384-dpuaG1suU0eT09tx5plTaGMLBsfDLzUCCUXOY2j/LSvXYuG6Bqs43ALlhIqAJVRb" crossorigin="anonymous" />
          <link href="/custom.css" rel="stylesheet" />
        </Helmet>
      ) }

      <ReactNotifications />
      <Route />
    </React.Fragment>
  );
}

export default App;
