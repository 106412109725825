import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { CustomMenu, CustomToggle } from "./../UI/LanguageSwitcher";
import AxiosInstance from "../../utilities/axios.instance";
import { Helmet } from "react-helmet";
const lngs = {
  en: { nativeName: "English" },
  ar: { nativeName: "عربي (Arabic)" },
};
export default function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const handleLanguageSwitch = (lng) => {
    AxiosInstance.defaults.headers["x-accept-language"] = lng;
    i18n.changeLanguage(lng);
  };
  return (
    <>
      
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {i18n.language.toUpperCase()}
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {Object.keys(lngs).map((lng, i) => (
            <Dropdown.Item key={i}>
              <li
                className="dropdown-item"
                key={lng}
                style={{
                  fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
                  listStyleType: "none",
                }}
                onClick={() => handleLanguageSwitch(lng)}
              >
                {lngs[lng].nativeName}
              </li>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
