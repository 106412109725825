import { createAction } from "redux-actions";

export const ANB_ACCOUNTS = "ANB_ACCOUNTS";
export const anbAccounts = createAction(ANB_ACCOUNTS);


export const ANB_TRANSACTION = "ANB_TRANSACTION";
export const anbTransaction = createAction(ANB_TRANSACTION);

export const ANB_TRANSFER = "ANB_TRANSFER";
export const anbTransfer = createAction(ANB_TRANSFER);
 
