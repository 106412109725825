import { all, takeLatest } from "redux-saga/effects";
import {
  FETCH_CARDS,
  ORDER_PHYSICAL_CARD,
  ORDER_VIRTUAL_CARD,
} from "../actions/card.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* fetchCards({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: `/paytabs/all-cards?id=${data.id}&idType=${data.idType}&page=${
      data.page
    }&size=${data.size}&cardStatus=${data.cardStatus}&last_digits=${
      data.last_digits || ""
    }`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
      //   yield put(anbSlice.actions.accountList(result?.data?.accounts));
    }
  }
}

function* orderPhysicalCard({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/issue-physical-card`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* orderVirtualCard({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/issue-virtual-card`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Cards() {
  yield all([takeLatest(FETCH_CARDS, fetchCards)]);
  yield all([takeLatest(ORDER_PHYSICAL_CARD, orderPhysicalCard)]);
  yield all([takeLatest(ORDER_VIRTUAL_CARD, orderVirtualCard)]);
}
export default Cards;
