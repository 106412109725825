import React, { useContext } from "react";

import AuthHeader from "../AuthHeader";
//import Footer from "../Footer";
import { Navigate, Outlet } from "react-router-dom";
import * as ROUTE from "../../../routes/routesConstant";
import { AuthContext } from "../../../contexts/AuthContext";
import SidebarAdmin from "../Sidebar/Sidebar.Admin";
import SidebarOwner from "../Sidebar/Sidebar.Owner";
import SidebarUser from "../Sidebar/Sidebar.User";

const sidebarTable = {
  OWNER: SidebarOwner,
  USER: SidebarUser,
  ADMIN: SidebarAdmin,
};
const PrivateLayout = (props) => {
  const auth = useContext(AuthContext);

  if (!auth) {
    return <Navigate to={{ pathname: ROUTE.SIGN_IN }} />;
  }
  const Sidebar = sidebarTable[auth?.user?.role || "OWNER"];
  return (
    <React.Fragment>
      <div id="wrapper">
        <Sidebar />
        <div className="mainContentWrapper">
          <AuthHeader />
          <div className="dash-cnt">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivateLayout;
