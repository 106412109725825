import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import { successMessage } from "../../utilities/notification";
import { businessSlice } from "../slices/business";

import {
  UPLOAD_DIRECTOR_ID,
  UPLOAD_ARTICLE_INC,
  DELETE_IMAGE,
  FETCH_DIRECTORS,
  SUBMIT_VERIFICATION_APPLICATION,
  GET_APPLICATION_STATUS,
  DELETE_APPLICATION,
  FETCH_DIRECTOR_FILE,
} from "../actions/business.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* uploadDirectorID({ payload: { director_id, data, callback } }) {
  const payload = {
    data: data,
    method: "post",
    url: `/company/doc-scan/${director_id}`,
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
    headers: { "Content-Type": "multipart/form-data" },
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(businessSlice.actions.uploadDirectorIDAction(result.data));

    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* uploadArticleInc({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/company/article-of-inc`,
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
    headers: { "Content-Type": "multipart/form-data" },
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(businessSlice.actions.uploadArticleIncAction(result.data));
    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* deleteImage({ payload: { imageId, callback } }) {
  const payload = {
    // data:null,
    method: "delete",
    url: `/company/doc/${imageId}`,
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(businessSlice.actions.deleteImageAction(result.data));

    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* fetchDirectors({ payload: { callback } }) {
  const payload = {
    data: null,
    method: "get",
    url: `/company/directors`,
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    // authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(businessSlice.actions.fetchDirectorsAction(result.data));

    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* submitApplication({ payload: { callback } }) {
  const payload = {
    // data: null,
    method: "post",
    url: `/company/application`,
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    // authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(businessSlice.actions.submitApplicationAction(result.data));

    if (callback) {
      callback({ success: true, data: null });
    }
  }
}
function* getApplicationStatus({ payload: { callback } }) {
  const payload = {
    // data: null,
    method: "get",
    url: `/company/application`,
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    // authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(businessSlice.actions.getApplicationStatusAction(result.data));

    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* deleteApplication({ payload: { callback, applicationId } }) {
  const payload = {
    // data:null,
    method: "delete",
    url: `/company/application/${applicationId}`,
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(businessSlice.actions.deleteApplicationAction(result.data));

    if (callback) {
      callback({ success: true, data: null });
    }
  }
}
function* fetchDirectorFile({ payload: { directorId, callback } }) {
  const payload = {
    // data:null,
    method: "get",
    url: `/company/doc/${directorId}`,
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(businessSlice.actions.fetchDirectorFileAction(result.data));

    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* Business() {
  yield all([
    takeLatest(UPLOAD_DIRECTOR_ID, uploadDirectorID),
    takeLatest(UPLOAD_ARTICLE_INC, uploadArticleInc),
    takeLatest(DELETE_IMAGE, deleteImage),
    takeLatest(FETCH_DIRECTORS, fetchDirectors),
    takeLatest(SUBMIT_VERIFICATION_APPLICATION, submitApplication),
    takeLatest(GET_APPLICATION_STATUS, getApplicationStatus),
    takeLatest(DELETE_APPLICATION, deleteApplication),
    takeEvery(FETCH_DIRECTOR_FILE, fetchDirectorFile),
  ]);
}

export default Business;
