import { combineReducers } from "@reduxjs/toolkit";
import { userslice } from "./user";
import { appslice } from "./app";
import createCompressor from "redux-persist-transform-compress";
import encryptor from "./encryptor";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { businessSlice } from "./business";
import { employerSlice } from "./employer";
import { paymentsSlice } from "./payments";
import { bfmSlice } from "./bfm";
import { anbSlice } from "./anb";
import { subscriptionSlice } from "./subscriptions";
import { settingSlice } from "./setting";

const reducers = combineReducers({
  user: userslice.reducer,
  app: appslice.reducer,
  business: businessSlice.reducer,
  employer: employerSlice.reducer,
  bfm: bfmSlice.reducer,
  payments: paymentsSlice.reducer,
  anb: anbSlice.reducer,
  subscriptions: subscriptionSlice.reducer,
  setting: settingSlice.reducer,
});

const compressor = createCompressor();

const config = {
  blacklist: ["app", "network", "toast"],
  key: "primary",
  storage,
  transforms: [encryptor, compressor],
};

export const persistedReducer = persistReducer(config, reducers);
