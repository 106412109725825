import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./pages/App/App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./contexts/AuthContext";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import configureStore from "./stores";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import './i18n';
import Loader from './components/Loader';
const { store } = configureStore();
let persistor = persistStore(store);
const app = document.getElementById('root');

// create a root
const root = createRoot(app);

//render app to root
root.render(<React.StrictMode>
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate loading={<Loader/>} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </HelmetProvider>
</React.StrictMode>);


reportWebVitals();
