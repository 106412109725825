import { all, takeLatest, put } from "redux-saga/effects";
import {
  GET_USER_PREFERENCE,
  UPDATE_USER_PREFERENCE,
} from "../actions/setting.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* getUserPreference({ payload: { callback } }) {
  const payload = {
    method: "get",
    url: "/preferences/user",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* updateUserPreference({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/preferences/user",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* Cards() {
  yield all([takeLatest(GET_USER_PREFERENCE, getUserPreference)]);
  yield all([takeLatest(UPDATE_USER_PREFERENCE, updateUserPreference)]);
}
export default Cards;
